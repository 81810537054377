import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '../core/base-api/base-api';
import { Router } from '@angular/router';
import { StorageService } from '../core/storage/storage.service';
import { AlertService } from '~/modules/core/alert/alert.service';
import { environment } from '../../../environments/environment';
import { Pharmacist } from '../models/pharmacy/pharmacist.model';

const API = environment.baseApiUrl;

@Injectable()
export class PharmacistService extends BaseApi {
  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getPharmacistsForTenant(): Observable<Pharmacist[]> {
    return this.get<Pharmacist[]>(`${API}/api/pharmacists`);
  }

  updatePharmacist(pharmacist: Pharmacist) {
    return this.put(`${API}/api/pharmacists`, pharmacist);
  }

  createPharmacist(pharmacist: Pharmacist) {
    return this.post(`${API}/api/pharmacists`, pharmacist);
  }
  deletePharmacist(pharmacistId: number) {
    return this.delete(`${API}/api/pharmacists/${pharmacistId}`);
  }
}
