import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AlertService } from '~/modules/core/alert/alert.service';
import { BaseApi } from '~/shared/core/base-api/base-api';
import { StorageService } from '~/shared/core/storage/storage.service';
import { CommsQueueItem } from '../models/comms-queue-item.model';
import { environment } from '../../../../../environments/environment';

const API = environment.baseApiUrl;

@Injectable()
export class CommsQueueService extends BaseApi {

  constructor(public http: HttpClient, public router: Router, public storageService: StorageService, public alertService: AlertService) {
    super(http, router, storageService, alertService);
  }

  getCommsQueueItems(startDate: Date): Observable<CommsQueueItem[]> {
    return this.get(`${API}/api/projection/pharmacy?fromDate=${moment(startDate).format('MM/DD/YYYY hh:mm:ss A')}&projectionDays=7`);
  }

  getPatientProjectedComms(clientId: string, startDate: Date): Observable<CommsQueueItem[]> {
    return this.get(`${API}/api/projection/${clientId}/patient?fromDate${moment(startDate).format('MM/DD/YYYY hh:mm:ss A')}?projectionDays=7`);
  }

}
